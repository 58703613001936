.container-body2 {
    height: 10vh;
    width: 125vh;
  }
  
  .post-box2 {
    width: 160000px;
    height: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .post-box2 .avatar2 {
    float: left;
    width: 52px;
    height: 52px;
    background-color: #ccc;
    border-radius: 25%;
    margin: 8px;
    background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
    background-size: 2000px;
    animation: shine-avatar 1.6s infinite linear;
  }

  .post-box2 .avatar2-action {
    float: left;
    width: 22px;
    height: 22px;
    background-color: #ccc;
    border-radius: 25%;
    background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
    background-size: 2000px;
    animation: shine-avatar 1.6s infinite linear;
    margin-left: 350px;
  }
  
  .post-box2 .line2 {
    width: 250px;
    height: 16px;
    border-radius: 7px;
    background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
    background-size: 2000px;
    animation: shine-lines 1.6s infinite linear;
  }
  
  .post-box2 .avatar2 + .line2 {
    padding-left: 110px;
    width: 200px;
  }
  
  .post-box2 .line2 ~ .line2 {
    background-color: #ddd;
    margin-left: 280px;
  }
  
  @keyframes shine-lines {
    0% {
      background-position: -200px;
    }
    
    40%, 100% {
      background-position: 240px;
    }
  }
  
  @keyframes shine-avatar {
    0% {
      background-position: -200px + 68;
    }
    
    40%, 100% {
      background-position: 140px + 68;
    }
  }
  @media screen and (max-width: 1323px) {
    .post-box2 .avatar2-action {
        margin-left: 150px;
    }
    .post-box2 .line2 ~ .line2 {
        margin-left: 80px;
      }
  }
  
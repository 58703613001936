.container-body {
  height: 20vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.post-box {
  width: 500px;
  height: 80px;
}

.post-box .avatar {
  float: left;
  width: 52px;
  height: 52px;
  background-color: #ccc;
  border-radius: 25%;
  margin: 8px;
  background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
  background-size: 2000px;
  animation: shine-avatar 1.6s infinite linear;
}

.post-box .line {
  float: left;
  width: 250px;
  height: 16px;
  margin-top: 12px;
  border-radius: 7px;
  background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
  background-size: 2000px;
  animation: shine-lines 1.6s infinite linear;
}

.post-box .avatar + .line {
  margin-top: 11px;
  width: 200px;
}

.post-box .line ~ .line {
  background-color: #ddd;
}

@keyframes shine-lines {
  0% {
    background-position: -200px;
  }
  
  40%, 100% {
    background-position: 240px;
  }
}

@keyframes shine-avatar {
  0% {
    background-position: -200px + 68;
  }
  
  40%, 100% {
    background-position: 140px + 68;
  }
}
